import axios from '../../common/axios.js'
import Modale from '../../common/ui/modale.vue'
import _ from 'lodash'
import CustomSelect from '../../common/forms/Select.vue'

export default {
	data(){
		return {
			erreur: false,
			encours: false,
			succes: false,
			nom: '',
			nbr_credits: 0,
			remerciement_csv: false,
			remerciement_crm: false,
			data_to_export: 'determine_dans_mounted',
			loadingCheck: true,
			credits: {"credits_before_export": 0, "exported_sites": 0, "consumed_credits": 0, "credits_after_export": -1}
		}
	},
	created(){

	},
	mounted(){
		this.data_to_export = this.exportsList[0].value
		this.loadingCheck     = true
		this.remerciement_csv = false
		this.remerciement_crm = false
		this.credits          = {"credits_before_export": 0, "exported_sites": 0, "consumed_credits": 0, "credits_after_export": -1}
		this.checkPossibility()
	},
	methods: {
		export_liste(mode){
			let self = this;

			if(mode == undefined || mode == '' || mode == 'datas'){
				if(this.$store.getters.fonctionnement == 'dechets')   mode = 'flux'
				if(this.$store.getters.fonctionnement == 'variables') mode = 'variables'
			}

      		axios.post('/plugin_projet/plugin_projet_iannuaire/export_list_shell', {mode, recherche_id: self.$store.state.ui.export_id, filters: self.$store.getters.getFiltersForExport})
				.then(function (response) {
					self.remerciement = true
				}).catch(function (error) {
					console.log(error);
				})

		},
		checkPossibility(){
			let self = this;
			axios.post("api=inex/companies/export/check-credits/{view_code}", this.$store.getters.getFiltersForExport, {headers: {'Content-Type': 'application/json'}})
			.then(function(response){
				self.loadingCheck = false
				self.credits = response.data
			})
		},		
		openPendingFlowModal(promise){
			let self = this

			let trad_code = []
			trad_code.push(self.$store.state.ui.export_type)
			trad_code.push(self.data_to_export == 'contacts' ? 'contacts' : 'waste')
			trad_code = trad_code.join('_')

			self.$store.dispatch('open_modal', {
				code: 'pending_api', params: {
					promise,
					pending_title: 'modal_export_pending_title_'+trad_code,
					pending_message: 'modal_export_pending_message_'+trad_code,
					success_title: 'modal_export_success_title_'+trad_code,
					success_message: 'modal_export_success_message_'+trad_code,
					error_title: 'modal_export_error_title_'+trad_code,
					error_message: 'modal_export_error_message_'+trad_code,
					error_html: '<p>' + self.$t('modal_export_error_message_contact_' + trad_code) +'<br><a href="mailto:'+self.$t('modal_export_error_message_contact_mail_'+trad_code)+'">'+self.$t('modal_export_error_message_contact_mail_'+trad_code)+'</a></p>'
				}
			})
		},
		getExport() {
			let self = this;
			if(!self.negatif){

				let code = self.data_to_export == 'contacts' ? 'contacts' : 'waste'

				//AS--> Cas de l'export CSV
				if(self.$store.state.ui.export_type == 'csv'){

					//AS--> On lance la mise à jour des crédits chez iNex
					/* let code = self.data_to_export == 'contacts' ? 'contacts' : 'waste'
					axios.post("api=inex/companies/export/xls/{view_code}/"+code, self.$store.getters.getFiltersForExport, {headers: {'Content-Type': 'application/json'}})
					.then(function (response) {
						//AS--> On lance l'export en CSV qui sera envoyé par mail si iNex répond par un succès
						self.export_liste(self.data_to_export)

					}).catch(function (error) {
						console.log(error);
					})*/
					const promise = axios.post("api=inex/companies/export/xls/{view_code}/"+code, self.$store.getters.getFiltersForExport, {
						headers: {'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
      					responseType: 'arraybuffer'
					}).then(function (response) {
						return Promise.resolve(response)
					}).catch(function (error) {
						console.log(error);
						return Promise.reject(error)
					})

					this.openPendingFlowModal(promise)					
				}
				
				//AS--> Cas de l'export vers un CRM
				if(self.$store.state.ui.export_type == 'crm'){
					const promise = axios.post("api=inex/companies/export/crm/{view_code}/" + code, self.$store.getters.getFiltersForExport, { headers: { 'Content-Type': 'application/json' } })
					this.openPendingFlowModal(promise)
				}

			}
		}
	},
	computed:{
		canExport(){
			if(this.$store.state.ui.export_type == 'csv'){
				return this.$store.getters.canExport
			}else if(this.$store.state.ui.export_type == 'crm'){
				return this.$store.getters.canExportCRM
			}else{
				return false
			}
		},
		exportType(){
			return this.$store.state.ui.export_type
		},
		negatif(){
			return this.credits.credits_after_export < 0
		},
		exportsList(){
			let list = [{label:this.$t('export_list_contacts'), value:'contacts'}]
			if(this.$store.getters.canExportData) list.push({label:this.$t('export_list_datas'), value:'datas'})
			return list;
		}
	},
	components:{
		CustomSelect,
		Modale
	}
}
